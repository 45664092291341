import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql, Link } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import Fade from 'react-reveal/Fade';

import { blogPostData } from '../mock/blog';

import React, { useState, useEffect } from 'react';

import { PortfolioProvider } from '../context/context';
import BlogHero from '../components/BlogHero/BlogHero';

export const BlogPostTemplate = ({
    content,
    contentComponent,
    description,
    tags,
    title,
}) => {
    const PostContent = contentComponent || Content

        const [hero, setHero] = useState({});


        useEffect(() => {
            setHero({ ...blogPostData });

        }, []);


       
    return (
        <PortfolioProvider value={{ hero }}>
            <BlogHero />           

        <section id="blog">
                <container >
                    <div className="blog-page">

            <h1 
                           >
                            {title}
                        </h1>
                        <p>{description}</p>
                    <PostContent className="blog-page__content" content={content} />
                        {tags && tags.length ? (
                            <div style={{ marginTop: `4rem` }}>
                                <h4>Tags</h4>
                                <ul className="blog-page__taglist">
                                    {tags.map((tag) => (
                                        <li key={tag + `tag`}>
                                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            ) : null}

                        </div>

                </container>
        </section>
        </PortfolioProvider>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
}

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data

    
    return (
        <Fade bottom duration={1000} delay={300} distance="0px">

        <div>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
            />
            </div>
            </Fade>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`